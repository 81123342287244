import React from 'react';
import { FailedAiScoreIndicator } from './FailedAiScoreIndicator';
import { PendingAiScoreIndicator } from './PendingAiScoreIndicator';
import { ScoreChip } from './ScoreChip';
import { SkippedStatusIndicator } from './SkippedStatusIndicator';
export const Score = ({ templateID, reviews }) => {
    const groundTruthReview = reviews.find((r) => r.template_id === templateID && r.latest);
    if (!groundTruthReview) {
        return React.createElement(ScoreChip, { status: "NONE", value: 0, isHidden: true });
    }
    if (groundTruthReview.not_applicable) {
        return React.createElement(ScoreChip, null);
    }
    switch (groundTruthReview.status) {
        case 'PENDING_TRANSCRIPT':
            return React.createElement(PendingAiScoreIndicator, null);
        case 'FAILED':
            return React.createElement(FailedAiScoreIndicator, null);
        case 'SKIPPED':
            return React.createElement(SkippedStatusIndicator, null);
        default:
            return (React.createElement(ScoreChip, { value: groundTruthReview.score_percentage, status: groundTruthReview.compliance_status }));
    }
};
